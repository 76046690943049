// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-aboutus-index-js": () => import("./../src/pages/aboutus/index.js" /* webpackChunkName: "component---src-pages-aboutus-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-livestreaming-faq-index-js": () => import("./../src/pages/livestreaming/faq/index.js" /* webpackChunkName: "component---src-pages-livestreaming-faq-index-js" */),
  "component---src-pages-livestreaming-index-js": () => import("./../src/pages/livestreaming/index.js" /* webpackChunkName: "component---src-pages-livestreaming-index-js" */),
  "component---src-pages-musicstuff-index-js": () => import("./../src/pages/musicstuff/index.js" /* webpackChunkName: "component---src-pages-musicstuff-index-js" */),
  "component---src-pages-whatwedo-index-js": () => import("./../src/pages/whatwedo/index.js" /* webpackChunkName: "component---src-pages-whatwedo-index-js" */)
}

